export default {
  noFound: {
    clickBack: 'назад',
    noFoundPage: 'Страница не найдена'
  },
  global: {
    title: 'Умная платформа управления конференциями',
    gotoLogin: 'Вернуться на авторизации',
    verifyIdentity: 'Подтвердить личность',
    resetPassword: 'Сброс пароля',
    finish: 'Завершить (Выполнено)',
    footer: 'Все права защищены©Гуанчжоу Baolun Electronics Co., Ltd.',
    userAgree: '《Сервисное соглашение》',
    and: 'и',
    privacyPolicy: '《политика конфиденциальности》',
    codeLogin: 'Вход с кодом подтверждения',
    passwordLogin: 'Логин с паролем',
    haveRead: 'Я прочитал и согласен',
    queryRead: "Пожалуйста, подтвердите, что я прочитал и согласен",
    meetingNo: '№ встречи',
    meetingTime: 'Время встречи',
    inviteYouTo: 'Пригласить вас',
    to: 'Входить'
  },
  axios: {
    expire: 'Срок действия идентификатора пользователя истек, обратитесь к администратору',
    noRegister: 'Ваш номер телефона не зарегистрирован, перейдите на страницу регистрации сейчас',
    pleaseLogin: 'Срок действия учетной записи истек, пожалуйста, войдите снова'
  },
  route: {
    home: 'Дом',
    userManage: 'Управление пользователями',
    meetingManage: 'Управление встречами',
    myMeeting: 'Моя встреча',
    createMeeting: 'Создать встречу',
    editMeeting: 'Изменить встречу',
    unitMeeting: 'Встреча группы',
    historyMeeting: 'Историческая встреча',
    meetingRecord: 'Запись встречи',
    meetingSetting: 'Настройки встречи',
    unitManage: 'Управление подразделением',
    orgStr: 'Организационная структура',
    unitInfo: 'Информация об устройстве',
    disUnit: 'Расформировать отряд',
    meetingRoom: 'Управление конференц-залом',
    flatPanel: 'Управлять устройством',
    persionalInfo: 'Личная информация',
    logout: 'Выйти',
    exitUnit: 'Выход из блока',
    disUser: 'Деактивированный пользователь',
    meetingMaterials: 'Файлы встреч',
    voteScore: 'Голосование и подсчет очков',
    personalManage: 'Кадровый менеджмент',
    signManage: 'Управление входом',
    looseUser: 'Свободный пользователь',
    createUnit: 'Создать объект',
    createSucess: 'Создано успешно',
    waitReview: 'Ожидание обзора',
    reviewRejected: 'Отзыв отклонен',
    interface: 'Статистика интерфейса',
    logManage: 'Управление журналом',
    advanced: 'Расширить',
    versionManage: 'Управление версиями',
    modify: 'Изменить',
    add: 'добавлять',
    meeting: 'Встреча',
    myRecord: 'Моя запись'
  },
  message: {
    message1: 'Укажите имя',
    message2: 'Имя не может содержать пробелы',
    message3: 'Необходим пароль',
    message4: 'Пароль не может содержать пробелов',
    message5: 'Пароль может содержать не более 8-16 цифр, состоящих из 3 или более 3 цифр, прописных букв, строчных букв и специальных символов.',
    message6: 'Пароль ограничен 8-16 цифрами',
    message7: 'Укажите имя',
    message8: 'Имя не может содержать специальные символы',
    message9: 'Длина имени не может быть больше 20Б',
    message10: 'Номер телефона не может быть пустым',
    message11: 'Пожалуйста, введите правильный номер телефона',
    message12: 'Необходимо заполнить проверочный код',
    message13: 'Код подтверждения не может содержать пробелы',
    message14: 'Длина кода подтверждения ограничена 6Б',
    message15: 'Адрес электронной почты не может быть пустым',
    message16: 'Пожалуйста, введите правильный адрес электронной почты',
    message17: 'Длина адреса не может быть больше 50Б',
    message18: 'Номер телефона не может содержать нечисловое содержимое',
    message19: 'Неверный формат номера телефона',
    message20: 'Длина позиции не может превышать 20Б',
    message21: 'Допускаются только буквы, цифры, знаки подчеркивания и китайские иероглифы.',
    message22: 'Вы вступили в организацию',
    message23: 'Код подтверждения отправлен на ваш мобильный телефон',
    message24: 'Ваша заявка не одобрена,Чтобы повторно подать заявку, нажмите',
    message25: 'Пожалуйста, заполните свое имя и пароль для входа',
    message26: 'Пожалуйста, заполните ваше имя',
    message27: 'Пожалуйста, проверьте, правильно ли введена информация',
    message28: 'Два введенных пароля не совпадают',
    tip1: 'Незарегистрированный номер телефона будет автоматически зарегистрирован после проверки',
    tip2: 'Пожалуйста, введите свой номер телефона',
    tip3: 'Пожалуйста, введите проверочный код',
    tip4: 'Введите пароль',
    tip5: 'За подробностями обращайтесь к администратору.',
    tip6: 'Нет названия встречи'
  },
  clickEvent: {
    login1: 'Войти сейчас',
    register: 'Зарегистрировать нового пользователя',
    forgot: 'Забыли пароль',
    getCode: 'Получить код подтверждения',
    reSend: 'Отправить',
    reApply: 'Подать заявку повторно',
    cInfo: 'Полная информация',
    back: 'Назад',
    regJoin: 'Зарегистрируйтесь и присоединяйтесь',
    accountExists: 'аккаунт уже существует',
    logNow: 'Log in now'
  },
  label: {
    name: 'имя',
    password: 'Пароль',
    confirmPassword: 'Подтвердите пароль',
    phoneNum: 'номер телефона',
    phoneEmail: "телефона/электронная почта",
    email: "электронная почта",
    code: 'Проверочный код'
  },
  all: {
    Message1: 'Срок действия идентификатора пользователя истек, обратитесь к администратору',
    Message2: 'Ваш номер телефона не зарегистрирован, перейдите на страницу регистрации сейчас',
    Message3: 'Истекший',
    Message4: 'Срок действия учетной записи истек, пожалуйста, войдите снова',
    Message5: 'Срок действия идентификатора пользователя истек',
    Message6: 'Дом',
    Message7: 'Управление встречами',
    Message8: 'Моя встреча',
    Message9: 'Создать встречу',
    Message10: 'Изменить встречу',
    Message11: 'Встреча группы',
    Message12: 'Историческая встреча',
    Message13: 'Запись встречи',
    Message14: 'Настройки встречи',
    Message15: 'Управление подразделением',
    Message16: 'Организационная структура',
    Message17: 'Информация об устройстве',
    Message18: 'Расформировать отряд',
    Message19: 'Управление конференц-залом',
    Message20: '一体机',
    Message21: 'Управление пользователями',
    Message22: 'Личная информация',
    Message23: 'Выйти',
    Message24: 'Выход из блока',
    Message25: 'Деактивированный пользователь',
    Message26: 'Файлы встреч',
    Message27: 'Голосование и подсчет очков',
    Message28: 'Кадровый менеджмент',
    Message29: 'Управление входом',
    Message30: 'Свободный пользователь',
    Message31: 'Создать объект',
    Message32: 'Создано успешно',
    Message33: 'Ожидание обзора',
    Message34: 'Отзыв отклонен',
    Message35: 'Интерактивная панель для конференций',
    Message36: 'Срок действия идентификатора истек, пожалуйста, войдите снова',
    Message37: 'Статистика интерфейса',
    Message38: 'Управление журналом',
    Message39: 'Расширить',
    Message40: 'Управление версиями',
    Message41: 'Изменить',
    Message42: 'добавлять',
    Message43: 'Встреча',
    Message44: 'Моя запись',
    Message45: 'Пекин',
    Message46: 'Майкрософт Яхей',
    Message47: 'Укажите имя',
    Message48: 'Имя не может содержать пробелы',
    Message49: 'Необходим пароль',
    Message50: 'Пароль не может содержать пробелов',
    Message51: 'Пароль может содержать не более {0} цифр, состоящих из {1} или более {2} цифр, прописных букв, строчных букв и специальных символов.',
    Message52: 'Пароль ограничен {0} цифрами',
    Message53: 'Укажите имя',
    Message54: 'Имя не может содержать специальные символы',
    Message55: 'Длина имени не может быть больше',
    Message56: 'Номер телефона не может быть пустым',
    Message57: 'Пожалуйста, введите правильный номер телефона',
    Message58: 'Необходимо заполнить проверочный код',
    Message59: 'Код подтверждения не может содержать пробелы',
    Message60: 'Длина кода подтверждения ограничена',
    Message61: 'Адрес электронной почты не может быть пустым',
    Message62: 'Пожалуйста, введите правильный адрес электронной почты',
    Message63: 'Длина адреса не может быть больше',
    Message64: 'Номер телефона не может содержать нечисловое содержимое',
    Message65: 'Неверный формат номера телефона',
    Message66: 'Длина позиции не может превышать',
    Message67: 'Допускаются только буквы, цифры, знаки подчеркивания и китайские иероглифы.',
    Message68: 'Не удалось прочитать информацию о пользователе, пожалуйста, войдите снова',
    Message69: 'Страница не найдена',
    Message70: 'назад',
    Message71: 'Платформа управления конференциями',
    Message72: 'Вы вступили в организацию',
    Message73: 'Вернуться на авторизации',
    Message74: 'Подтвердить личность',
    Message75: 'Сброс пароля',
    Message76: 'Завершить (Выполнено)',
    Message77: 'Все права защищены',
    Message78: 'Гуанчжоу Baolun Electronics Co., Ltd.',
    Message79: '《Сервисное соглашение》',
    Message80: 'и',
    Message81: '《политика конфиденциальности》',
    Message82: 'Вход с кодом подтверждения',
    Message83: 'Логин с паролем',
    Message84: 'Незарегистрированный номер телефона будет автоматически зарегистрирован после проверки',
    Message85: 'Пожалуйста, введите свой номер телефона',
    Message86: 'Пожалуйста, введите проверочный код',
    Message87: 'Я прочитал и согласен',
    Message88: 'Авторизоваться',
    Message89: 'Зарегистрировать нового пользователя',
    Message90: 'Забыли пароль',
    Message91: 'Введите пароль',
    Message92: 'Получить код подтверждения',
    Message93: 'Отправить',
    Message94: 'Код подтверждения отправлен на ваш мобильный телефон',
    Message95: 'Ваша заявка не одобрена',
    Message96: 'Чтобы повторно подать заявку, нажмите',
    Message97: 'Подать заявку повторно',
    Message98: 'За подробностями обращайтесь к администратору.',
    Message99: 'Нет названия встречи',
    Message100: '№ встречи:',
    Message101: 'Время встречи:',
    Message102: 'Полная информация',
    Message103: 'Пожалуйста, заполните имя пользователя и пароль для входа',
    Message104: 'Назад',
    Message105: 'имя',
    Message106: 'Пожалуйста, заполните ваше имя',
    Message107: 'Пароль',
    Message108: 'Подтвердите пароль',
    Message109: 'Зарегистрируйтесь и присоединяйтесь',
    Message110: 'Пожалуйста, проверьте, правильно ли введена информация',
    Message111: 'Два введенных пароля не совпадают',
    Message112: 'Пригласить вас',
    Message113: 'Входить',
    Message114: 'номер телефона',
    Message115: 'Проверочный код',
    Message116: 'аккаунт уже существует',
    Message117: 'Войти сейчас',
    Message118: 'Срок действия ссылки истек, вы можете повторно скопировать ссылку, чтобы войти на страницу приглашения',
    Message119: 'Вы подали заявку на вступление в другую организацию',
    Message120: 'Вы присоединились к организации и не можете присоединиться к ней снова',
    Message121: 'Приглашение принято успешно, вы войдете в систему напрямую',
    Message122: 'Следующий шаг',
    Message123: 'Совет. Если ваш мобильный телефон и электронная почта недоступны, обратитесь к администратору предприятия, чтобы обновить мобильный телефон и электронную почту как можно скорее.',
    Message124: 'Для восстановления паролей можно использовать только телефонные номера в материковом Китае.',
    Message125: 'Номер телефона не зарегистрирован, вы можете перейти на страницу регистрации, чтобы зарегистрироваться',
    Message126: 'Новый пароль',
    Message127: 'Пожалуйста, подтвердите свой пароль',
    Message128: 'Совет',
    Message129: ' цифры, английские буквы и символы',
    Message130: 'Два входных пароля несовместимы',
    Message131: 'Пароль успешно изменен',
    Message132: 'Обратный отсчет',
    Message133: ' секунд спустя',
    Message134: 'Регистрация нового пользователя',
    Message135: 'Этот номер телефона зарегистрирован, вы можете войти напрямую',
    Message136: 'Представлять на рассмотрение',
    Message137: 'Поздравляем, вы успешно зарегистрировались',
    Message138: 'Об автоматическом входе в систему',
    Message139: 'Поиск по названию встречи',
    Message140: 'Вернуться домой',
    Message141: 'Выйти',
    Message142: 'Выйти?',
    Message143: 'Фон управления конференциями',
    Message144: 'Отменить',
    Message145: 'OK',
    Message146: 'Уведомление',
    Message147: 'личный',
    Message148: 'Управление',
    Message149: 'Нет релевантных данных',
    Message150: 'Нет.',
    Message151: 'Не начался',
    Message152: 'В ходе выполнения',
    Message153: 'Завершено',
    Message154: 'Администратор',
    Message155: 'Общий пользователь',
    Message156: 'Нажмите, чтобы загрузить',
    Message157: 'За один раз можно загрузить не более {0} файлов; общее количество файлов не может превышать {1}; имя файла не может превышать {2} символов.',
    Message158: 'Вы хотите удалить?',
    Message159: 'Дата начала',
    Message160: 'Дата окончания',
    Message161: 'Пожалуйста выберите',
    Message162: 'На прошлой неделе',
    Message163: 'прошлый месяц',
    Message164: 'Последние 3 месяца',
    Message165: 'Все',
    Message166: 'Программа обновления',
    Message167: 'Сторона клиента',
    Message168: 'Заградительная загрузка',
    Message169: 'Вершина',
    Message170: 'Нижний',
    Message171: 'Прокрутить',
    Message172: 'Заполните заграждение и нажмите Enter, чтобы отправить',
    Message173: 'об авторе',
    Message174: 'Отзывы игроков',
    Message175: 'Справка',
    Message176: 'игрок',
    Message177: 'Петля',
    Message178: 'Скорость',
    Message179: 'Прозрачность заграждения',
    Message180: 'Нормальный',
    Message181: 'Пожалуйста, заполните заграждение содержание!',
    Message182: 'Установите цвет заграждения',
    Message183: 'Установите тип заграждения',
    Message184: 'Показать заграждение',
    Message185: 'Ошибка загрузки видео',
    Message186: 'Загрузка заграждения не удалась',
    Message187: 'Не удалось отправить заграждение',
    Message188: 'Переключение на',
    Message189: 'Переключился на',
    Message190: 'Качество изображения',
    Message191: 'Перемотка вперед',
    Message192: 'Быстрая перемотка',
    Message193: 'Массивный заградительный огонь',
    Message194: 'послать заградительный огонь',
    Message195: 'Настроить',
    Message196: 'Полноэкранный',
    Message197: 'Полноэкранный',
    Message198: 'отправлять',
    Message199: 'Скриншот',
    Message200: 'Беспроводное совместное использование экрана',
    Message201: 'С',
    Message202: 'Показать субтитры',
    Message203: 'Скрыть субтитры',
    Message204: 'Громкость',
    Message205: 'жить',
    Message206: 'Статистика видео',
    Message207: 'Среднее время отклика',
    Message208: 'Количество звонков',
    Message209: 'Поиск',
    Message210: 'Имя',
    Message211: 'Аккаунт был отключен, пожалуйста, свяжитесь с менеджером подразделения для деталей',
    Message212: 'Ресурсы юнита:',
    Message213: 'Использование:',
    Message214: 'Ежедневная статистика посещаемости',
    Message215: 'Статистика продолжительности ежедневных встреч',
    Message216: 'Ежедневная статистика встреч',
    Message217: 'Ежедневная статистика активных пользователей',
    Message218: 'в прошлом году',
    Message219: 'Сегодняшние участники',
    Message220: 'конференц-залов',
    Message221: 'Учетная запись пользователя',
    Message222: 'Сегодняшние встречи',
    Message223: 'Продолжительность сегодняшней встречи(минут) ',
    Message224: 'Сегодняшние активные пользователи',
    Message225: 'Продолжительность сегодняшней встречи',
    Message226: 'Сегодняшние встречи',
    Message227: '',
    Message228: 'Сегодняшние активные пользователи',
    Message229: 'Вы подали заявку на присоединение к {msg}; ее можно использовать после одобрения администратором',
    Message230: 'Заявка отозвана',
    Message231: 'Вы хотите отозвать свою заявку на вступление в {msg}?',
    Message232: 'Ваша заявка одобрена',
    Message233: 'Ваша заявка отклонена',
    Message234: 'Проверка не удалась, пожалуйста, свяжитесь с администратором для деталей',
    Message235: 'Название объекта',
    Message236: 'Пожалуйста, заполните название подразделения',
    Message237: 'Пожалуйста, введите название устройства',
    Message238: 'Длина имени ограничена2~20китайский-английский',
    Message239: 'китайский-английский',
    Message240: 'Допускаются только китайские или английские буквы.',
    Message241: 'Объект успешно создан',
    Message242: 'Пригласите коллег присоединиться',
    Message243: 'Введите единицу измерения',
    Message244: 'Вы еще не создали подразделение, пожалуйста, создайте его или присоединитесь к нему',
    Message245: 'Загрузка данных...пожалуйста, подождите',
    Message246: 'пожалуйста, подождите',
    Message247: 'новый узел',
    Message248: 'Пожалуйста, заполните имя узла',
    Message249: 'Удалить узел',
    Message250: 'Этот узел имеет пользователей и не может быть удален',
    Message251: 'Вы хотите удалить узел?',
    Message252: 'Изменить узел',
    Message253: 'Имя узла не может быть пустым',
    Message254: 'Узел успешно удален',
    Message255: 'Описание расформирования отряда',
    Message256: 'Расформирование отряда является необратимой операцией.При расформировании отряда все его участники становятся отдельными учетными записями пользователей.',
    Message257: 'Прежде чем распустить отряд, подтвердите:',
    Message258: 'Файлы, содержащиеся на вашем устройстве, были правильно сохранены: включая, помимо прочего, записи совещаний и т. д.',
    Message259: 'В блоке отсутствуют споры: в том числе жалобы и отчеты, арбитраж, судебные разбирательства и так далее.',
    Message260: 'Номер телефона:',
    Message261: 'Проверочный код:',
    Message262: 'Приобретать',
    Message263: 'Подразделение было успешно расформировано',
    Message264: 'Получить',
    Message265: 'Сначала согласитесь с описанием расформирования отряда.',
    Message266: 'Привязать электронную почту',
    Message267: 'Электронная почта:',
    Message268: 'Пожалуйста, введите свой адрес электронной почты',
    Message269: 'Проверочный код:',
    Message270: 'Электронная почта успешно привязана',
    Message271: 'Текущий адрес электронной почты:',
    Message272: 'Этот адрес электронной почты зарегистрирован',
    Message273: 'Подтвердить',
    Message274: 'Этот номер телефона зарегистрирован',
    Message275: 'Изменено успешно',
    Message276: 'Изменить адрес электронной почты',
    Message277: 'Исходное Почта:',
    Message278: 'Новый E-mail:',
    Message279: 'Совет: После смены пароля произойдет автоматический выход из системы.',
    Message280: 'Старый пароль',
    Message281: 'Пожалуйста, введите старый пароль',
    Message282: 'Пожалуйста, введите новый пароль',
    Message283: 'Два новых введенных пароля несовместимы',
    Message284: 'Пароль успешно изменен',
    Message285: 'Изменить номер телефона',
    Message286: 'Новый номер телефона',
    Message287: 'Пожалуйста, введите новый номер телефона',
    Message288: 'Номер телефона успешно изменен',
    Message289: 'Номер телефона изменен на:',
    Message290: 'Название подразделения:',
    Message291: 'Сохранить',
    Message292: 'Адрес:',
    Message293: 'Единица:',
    Message294: 'Учетная запись администратора:',
    Message295: 'Пароль администратора:',
    Message296: 'Телефон администратора:',
    Message297: 'связывать',
    Message298: 'Электронная почта администратора:',
    Message299: 'изменить пароль',
    Message300: 'Изменить номер мобильного телефона',
    Message301: 'Привязать номер телефона',
    Message302: 'Нет данных',
    Message303: 'нет адреса',
    Message304: 'Ошибка получения информации о арендаторе',
    Message305: 'Название объекта не может быть пустым',
    Message306: 'характер',
    Message307: 'Допускаются только английские буквы и китайские иероглифы.',
    Message308: 'Адрес не может быть пустым',
    Message309: 'Длина адреса не может превышать',
    Message310: 'Адрес не может быть пустым!',
    Message311: 'Адрес не может содержать пробелы',
    Message312: 'Счет',
    Message313: 'Время работы',
    Message314: 'Принадлежность модуля',
    Message315: 'Тип операции',
    Message316: 'Детали операции',
    Message317: 'вид',
    Message318: 'Имя модуля',
    Message319: 'Экспорт журнала',
    Message320: 'Удалить',
    Message321: 'Операция',
    Message322: 'Удалить журналы',
    Message323: 'Вы хотите удалить выбранные журналы?',
    Message324: 'Пожалуйста, выберите элемент для удаления',
    Message325: 'Пожалуйста, выберите элемент для экспорта',
    Message326: 'Подробности журнала',
    Message327: 'Журналы операций',
    Message328: 'Список журналов операций',
    Message329: 'Нет подробностей',
    Message330: 'Удалено успешно',
    Message331: 'Название встречи',
    Message332: 'Букер',
    Message333: 'Предприятие/Организация',
    Message334: 'Организация',
    Message335: 'Номер встречи',
    Message336: 'Время начала',
    Message337: 'записывать',
    Message338: 'проверьте детали',
    Message339: 'Экспорт',
    Message340: 'Да',
    Message341: 'Нет',
    Message342: 'Конфиденциальность конференции',
    Message343: 'Удаление исторических совещаний приведет к удалению данных, связанных с совещанием.',
    Message344: 'Вы хотите удалить выбранные исторические встречи?',
    Message345: 'Файлы встреч',
    Message346: 'Имя файла',
    Message347: 'скачать',
    Message348: 'предварительный просмотр',
    Message349: 'Файлы повестки дня',
    Message350: 'Файлы темы',
    Message351: 'Временные файлы',
    Message352: 'Скринкаст встречи',
    Message353: 'Файлы упаковки встреч',
    Message354: 'Этот тип недоступен для предварительного просмотра',
    Message355: 'Продолжительность',
    Message356: 'Размер файла',
    Message357: 'Вы хотите удалить файлы записи?',
    Message358: 'Если файл большой, скачивание займет много времени. Хотите скачать?',
    Message359: 'Карта по умолчанию без данных',
    Message360: 'Файлы записи встречи',
    Message361: 'ошибка загрузки',
    Message362: 'Не удалось удалить',
    Message363: 'отделение',
    Message364: 'Число участников',
    Message365: 'Состояние',
    Message366: 'Изменить',
    Message367: 'Завершить',
    Message368: 'Вы хотите отменить встречу?',
    Message369: 'Текущая встреча зарезервирована {0}',
    Message370: 'Отправьте SMS-уведомление букмекеру',
    Message371: 'Текущая встреча',
    Message372: 'Все участники были оповещены по СМС.',
    Message373: 'Отправка SMS-уведомления всем участникам',
    Message374: 'Идет конфиденциальная встреча, вы хотите принудительно завершить ее?',
    Message375: 'Текущая встреча продолжается, пожалуйста, сохраните файлы встречи на интерактивном дисплее.',
    Message376: 'Нажмите [OK], чтобы принудительно завершить собрание.',
    Message377: 'Отменено успешно',
    Message378: 'Встреча успешно остановлена',
    Message379: 'безопасный',
    Message380: '资料加密：',
    Message381: 'Выберите, следует ли шифровать файлы собраний',
    Message382: 'Зашифровать (файлы собраний можно открыть только в терминале)',
    Message383: 'Не шифровать',
    Message384: ' Примечание. Данные совещания шифруются и загружаются, и их можно открывается терминалом приложения; обычные пользователи могут расшифровывать и экспортировать материалы собраний в исторических собраниях, но не могут просматривать детали',
    Message385: 'открывается терминалом приложения; обычные пользователи могут расшифровывать и экспортировать материалы собраний в исторических собраниях, но не могут просматривать детали',
    Message386: 'Загрузка данных',
    Message387: 'Пожалуйста, выберите, будет ли встреча конфиденциальной',
    Message388: 'Не разрешать загрузки',
    Message389: 'Разрешить загрузку',
    Message390: 'Примечание: материалы исторических встреч могут быть загружены обычными пользователями.',
    Message391: 'Время удержания',
    Message392: 'Пожалуйста, выберите время ожидания',
    Message393: 'Сбросить настройки',
    Message394: 'постоянный',
    Message395: '6 месяцев',
    Message396: '12 месяцев',
    Message397: 'Пожалуйста, выберите часовой пояс',
    Message398: 'Пожалуйста, выберите, следует ли шифровать',
    Message399: 'Выберите, следует ли сохранить конфиденциальность',
    Message400: 'Успешно сохранено',
    Message401: 'Сбросить успешно',
    Message402: 'Загрузчик',
    Message403: 'Время загрузки',
    Message404: 'Повестка дня встречи',
    Message405: 'Форма объекта',
    Message406: 'Сим Хей',
    Message407: 'Абсолютный шрифт',
    Message408: 'Клык Песня',
    Message409: 'КайТи',
    Message410: 'Биау Кай',
    Message411: ' STFangsong',
    Message412: 'СТКаити',
    Message413: 'Пользовательский',
    Message414: ' Загружаемый',
    Message415: ' формат документов, сложный',
    Message416: ' документы должны быть преобразованы',
    Message417: 'перед загрузкой (максимальный размер файла поддерживается{msg})',
    Message418: 'Файл повестки дня создается...请稍后',
    Message419: 'Вы хотите удалить файл повестки дня?',
    Message420: 'Пользовательская повестка дня',
    Message421: 'Прозрачный',
    Message422: 'Автоматически генерировать',
    Message423: 'Местонахождение конференц-зала пока не определено',
    Message424: 'Нет информации о хосте',
    Message425: 'Время встречи',
    Message426: 'Место встречи',
    Message427: 'Организатор встречи',
    Message428: 'Участники',
    Message429: 'Это действие перезапишет исходный файл повестки дня.',
    Message430: 'Вы хотите продолжать?',
    Message431: 'Перезапись и загрузка отменены',
    Message432: 'Встреча закончилась, файл не может быть загружен',
    Message433: 'Размер файла не может превышать',
    Message434: 'Файлы этого формата не могут быть загружены',
    Message435: 'Загружено успешно',
    Message436: 'Ошибка обработки файла',
    Message437: 'Добавить тему',
    Message438: 'Скачать документы по теме',
    Message439: 'Просмотр разрешенных форматов загрузки',
    Message440: 'Ключевые слова темы поиска',
    Message441: 'Название темы',
    Message442: 'Время создания',
    Message443: 'Тема Описание',
    Message444: 'Связанные документы',
    Message445: 'Удаление темы также приведет к удалению связанных файлов',
    Message446: 'Удалить?',
    Message447: 'Загрузка файлов слишком большого размера займет много времени',
    Message448: 'В течение этого периода это не повлияет на другие ваши операции.',
    Message449: 'Вы хотите скачать?',
    Message450: 'Разрешенные форматы загрузки',
    Message451: 'Предварительный просмотр ресурсов собрания',
    Message452: 'Загрузить связанные файлы',
    Message453: 'Название темы и описание не могут быть пустыми',
    Message454: 'Просмотр темы',
    Message455: 'Изменить тему',
    Message456: 'Удалено успешно, если страница не обновляется, обновите страницу',
    Message457: 'За один раз можно загрузить не более 100 файлов; общее количество файлов не может превышать 200; имя файла не может превышать 80.',
    Message458: 'Совет: Если в загруженном документе есть гиперссылка, транскодирование файла не удастся. Удалите гиперссылку и загрузите файл снова.',
    Message459: 'Формат недействителен и не может быть загружен',
    Message460: 'Есть файлы, которые не удалось перекодировать, и они будут удалены из списка загрузки.',
    Message461: 'Имя файла',
    Message462: 'Загрузить файл',
    Message463: 'Скачать файлы',
    Message464: 'Ключевые слова файла поиска',
    Message465: 'Вы хотите удалить выбранные файлы?',
    Message466: 'Встреча с мухами',
    Message467: 'Файл больше, чем2GB,Не удалось загрузить',
    Message468: 'Не удалось загрузить',
    Message469: 'Модель устройства',
    Message470: 'Название голосования',
    Message471: 'Способ регистрации',
    Message472: 'Анонимное',
    Message473: 'Настоящее имя',
    Message474: 'Правила голосования',
    Message475: 'Большой выбор',
    Message476: 'Единый выбор',
    Message477: 'Метод подсчета',
    Message478: 'Ручное голосование',
    Message479: 'Голоса за приложение',
    Message480: 'Добавить время',
    Message481: 'Зачетный титул',
    Message482: 'Тип подсчета очков',
    Message483: 'Устройство',
    Message484: 'Код активации',
    Message485: 'не в сети',
    Message486: 'Всети',
    Message487: 'На встрече',
    Message488: 'Отвязать',
    Message489: 'Добавить',
    Message490: 'Название конференц-зала',
    Message491: 'Вы хотите удалить конференц-зал?',
    Message492: 'В этом конференц-зале есть интерактивный дисплей, снимите его перед началом работы.',
    Message493: 'Добавить устройство',
    Message494: 'Выберите конференц-зал',
    Message495: 'Пожалуйста, выберите конференц-зал',
    Message496: 'Изменить устройство',
    Message497: 'Название устройства',
    Message498: 'Код активации устройства',
    Message499: 'Вы хотите развязаться?',
    Message500: 'Вы хотите удалить выбранное устройство?',
    Message501: 'Пожалуйста, введите название конференц-зала',
    Message502: 'Пожалуйста, введите имя устройства',
    Message503: 'Пожалуйста, введите код активации устройства',
    Message504: 'Не активировано (осталось{msg} дней)',
    Message505: 'В текущем списке устройств нет данных',
    Message506: 'Список устройств',
    Message507: 'Это название конференц-зала уже существует',
    Message508: 'Устройство успешно добавлено',
    Message509: 'Массив занятых конференц-залов',
    Message510: 'Массив всех конференц-залов',
    Message511: 'Отвязано успешно',
    Message512: 'Добавить код активации',
    Message513: 'Экспорт устройства',
    Message514: 'Имя поиска',
    Message515: 'Нет карты встречи по умолчанию',
    Message516: 'Нет встречи. Чтобы запланировать встречу, нажмите',
    Message517: 'Вы хотите отменить встречу?',
    Message518: 'Все участники были оповещены по СМС.',
    Message519: 'Участники будут уведомлены по SMS после отмены',
    Message520: 'Вы хотите закончить встречу?',
    Message521: 'Текущая встреча продолжается, пожалуйста, нажмите',
    Message522: 'Завершить встречу',
    Message523: 'Отменить встречу',
    Message524: 'Время',
    Message525: 'Место',
    Message526: 'Пароль встречи',
    Message527: 'Ведущий',
    Message528: 'Нет пароля',
    Message529: 'Комната для переговоров еще не выделена',
    Message530: 'сегодня',
    Message531: 'Последние 7 дней',
    Message532: 'Последние 30 дней',
    Message533: 'Настройка оценки',
    Message534: 'Правила подсчета очков',
    Message535: 'взять среднее значение',
    Message536: 'Усеченное среднее',
    Message537: 'Нажмите кнопку «Добавить параметр», чтобы вручную добавить необходимые параметры.',
    Message538: 'Вариант',


    Message539: 'Добавить вариант',
    Message540: 'Максимальное значение — {max}, минимальное значение — {min}',
    Message541: 'Изменить оценку',
    Message542: 'Для оценки можно ввести только цифры',
    Message543: 'Добавить оценку',
    Message544: 'Варианты подсчета очков',
    Message545: 'Название рейтинга не может быть пустым',
    Message546: '选项内容不能为空',
    Message547: 'Тип голосования',
    Message548: 'Метод подсчета',
    Message549: 'Отсканируйте код, чтобы проголосовать',
    Message550: 'Необходимые опции можно добавить вручную,',
    Message551: 'Название для голосования обязательно, а количество вариантов больше двух',
    Message552: 'Изменить голосование',
    Message553: 'Вариант голосования',
    Message554: 'Вариантов голосования не менее двух и название обязательно',
    Message555: 'Добавить голосование',
    Message556: 'Голосовать',
    Message557: 'Оценка',
    Message558: 'Экспорт результатов',
    Message559: 'Подсчет результатов',
    Message560: 'Результаты',
    Message561: 'Оценка предметов',
    Message562: 'Общий',
    Message563: 'Средний',
    Message564: 'Высший балл',
    Message565: 'Самая низкая оценка',
    Message566: 'Количество оценщиков',
    Message567: 'Средняя стоимость',
    Message568: 'Усеченное среднее',
    Message569: 'Всего участников:',
    Message570: 'Усеченное среднее',
    Message571: 'Усеченное среднее',
    Message572: 'Результаты голосования',
    Message573: 'Текущее голосование является анонимным и не может быть просмотрено',
    Message574: ' Голосование по имени',
    Message575: ' Голосование по имени',
    Message576: ' является анонимным и не может быть экспортировано',
    Message577: 'голосование',
    Message578: 'Имя для голосования:',
    Message579: 'Правила голосования:',
    Message580: 'Итоги голосования:',
    Message581: 'Имя избирателя',
    Message582: 'Выбор избирателя',
    Message583: 'Голосование без названия',
    Message584: 'Вернуть данные',
    Message585: 'Варианты голосования:',
    Message586: 'Количество избирателей:',
    Message587: 'Название опции:',
    Message588: '{0} человек проголосовали',
    Message589: 'Пользователь не имеет информации об имени',
    Message590: 'Пожалуйста, введите название встречи',
    Message591: 'книга',
    Message592: 'Выберите дату',
    Message593: 'Пожалуйста, заполните конференц-зал',
    Message594: 'Выберите период',
    Message595: 'Тип встречи',
    Message596: 'Обычная встреча',
    Message597: 'Видеоконференция',
    Message598: 'Пароль докладчика',
    Message599: 'Загрузка данных',
    Message600: 'После того, как загрузка данных будет включена, обычным пользователям будет разрешено загружать данные собрания после собрания.',
    Message601: 'Шифрование данных',
    Message602: 'После включения шифрования зашифрованные данные совещания могут быть  открыто и просмотрено с помощью терминала приложения',
    Message603: '',
    Message604: 'Пожалуйста, введите название встречи',
    Message605: 'Длина имени собрания ограничена{msg}',
    Message606: 'Пожалуйста, проверьте, действителен ли пароль',
    Message607: 'здесь',
    Message608: 'Аннулирование аккаунта',
    Message609: 'Удаление учетной записи является необратимой операцией.После аннулирования учетной записи вы не сможете войти в систему, чтобы использовать учетную запись.',
    Message610: 'Перед удалением учетной записи подтвердите:',
    Message611: 'После отмены учетной записи данные собрания, зарезервированные учетной записью, будут удалены.',
    Message612: 'Такие данные, как исторические встречи, завершенные учетной записью, принадлежат предприятию и не будут удалены.',
    Message613: 'Файлы, содержащиеся в вашей учетной записи, были правильно сохранены: включая, помимо прочего, записи совещаний и т. д.',
    Message614: 'Подтвердить выход',
    Message615: 'Аутентификация',
    Message616: 'В целях безопасности вашей учетной записи сначала подтвердите свою личность. После удаления учетной записи вы сразу же выйдете из системы.',
    Message617: 'Метод аутентификации',
    Message618: 'Аккаунт успешно удален',
    Message619: 'Нажмите, чтобы установить время',
    Message620: 'дата',
    Message621: 'Гостевой пароль',
    Message622: 'Создать сейчас',
    Message623: 'Забронировать встречу',
    Message624: 'Название встречи пусто, пожалуйста, введите название встречи',
    Message625: 'Пожалуйста, выберите дату начала встречи',
    Message626: 'Пожалуйста, выберите время начала встречи',
    Message627: 'Чтобы установить пароль собрания, длина пароля должна быть 6 цифр.',
    Message628: 'длина пароля должна быть 6 цифр.',
    Message629: 'Встреча успешно создана',
    Message630: ' встреча',
    Message631: '点击确定完成预约{msg}或点击另外时间节点完成预约',
    Message632: 'Этот период времени зарезервирован пользователем {msg}; щелкните другой временной узел, чтобы завершить резервирование.',
    Message633: 'В этот период была встреча',
    Message634: 'Нажмите OK, чтобы завершить бронирование.',
    Message635: 'Вы хотите выбрать этот период времени?',
    Message636: 'часы',
    Message637: 'Внимание',
    Message638: ' После выбора периода времени, если вы не нажмете OK в маленьком окне подсказки,',
    Message639: 'выбранное время не вступит в силу',
    Message640: 'Время бронирования можно выбрать только в течение последних семи дней.',
    Message641: 'Если одновременно зарезервировано несколько конференц-залов, вступит в силу только последний зарезервированный конференц-зал.',
    Message642: 'Бронирование может быть сделано только в течение одной недели с сегодняшнего дня, в противном случае бронирование не будет выполнено.',
    Message643: 'Букер',
    Message644: 'Записывать ли',
    Message645: 'Имя:',
    Message646: 'Номер телефона:',
    Message647: 'Электронная почта:',
    Message648: 'Нет электронной почты',
    Message649: 'Счет:',
    Message650: 'Логин Пароль:',
    Message651: 'Текущая единица измерения:',
    Message652: 'нет компании',
    Message653: 'Перейти к созданию объекта',
    Message654: 'Перейти на страницу ожидания проверки',
    Message655: 'Привязать номер телефона',
    Message656: 'Без отдела',
    Message657: 'позиция',
    Message658: 'нет позиции',
    Message659: 'Нет данных администратора',
    Message660: 'видеть',
    Message661: 'Имя пользователя не может содержать специальные символы',
    Message662: 'Выход Описание блока',
    Message663: 'Выход из блока является необратимой операцией.После выхода из блока:',
    Message664: 'Все связанные с бизнесом данные в вашем аккаунте будут удалены.',
    Message665: 'После этого ваша учетная запись становится отдельной индивидуальной учетной записью пользователя.',
    Message666: 'Успешно вышел из блока',
    Message667: 'Сначала согласитесь с описанием модуля выхода.',
    Message668: 'позиция',
    Message669: 'роль',
    Message670: 'Хозяин',
    Message671: 'Участник',
    Message672: 'Временный персонал',
    Message673: 'Время присоединения',
    Message674: 'По отделам',
    Message675: 'Список пользователей',
    Message676: 'Выбранный список',
    Message677: 'Выберите участников',
    Message678: 'Добавлено успешно',
    Message679: 'Не удалось добавить',
    Message680: 'Шаблон файла',
    Message681: 'Нажмите, чтобы скачать шаблон',
    Message682: 'Импорт файла',
    Message683: 'Нажмите, чтобы импортировать файл',
    Message684: 'Временный шаблон импорта персонала',
    Message685: 'Пожалуйста, загрузите файл, соответствующий формату',
    Message686: 'Отправить уведомление о встрече',
    Message687: 'Добавить участников',
    Message688: 'Добавить временный персонал',
    Message689: 'Импорт временного персонала',
    Message690: 'Экспорт списка участников',
    Message691: 'Поиск имен участников',
    Message692: 'Уведомление о собрании',
    Message693: 'Изменить временный персонал',
    Message694: 'Вы хотите удалить выбранного человека',
    Message695: 'Список участников',
    Message696: 'Шаблон SMS',
    Message697: 'Содержимое шаблона',
    Message698: 'Выбрать все',
    Message699: 'Успешно отправлено',
    Message700: 'Не удалось отправить',
    Message701: 'Этот номер телефона уже существует',
    Message702: 'Этот номер телефона уже существует',
    Message703: 'Экспорт списка входа',
    Message704: 'Поиск номера телефона',
    Message705: 'Нет данных для экспорта',
    Message706: 'Описание встречи',
    Message707: 'без описания',
    Message708: "Время входа",
    Message709: 'Список входа',
    Message710: 'к',
    Message711: 'Вы хотите удалить файлы записи?',
    Message712: 'Информация о файле успешно получена',
    Message713: 'Тип программы',
    Message714: 'Обновление',
    Message715: '№ версии',
    Message716: 'Пожалуйста, введите номер версии',
    Message717: 'Примечания к выпуску',
    Message718: 'Пожалуйста, заполните информацию о версии',
    Message719: 'Пожалуйста, заполните обновленную информацию',
    Message720: 'Файл версии',
    Message721: 'Не удалось отредактировать',
    Message722: 'Попробуйте загрузить снова',
    Message723: 'В настоящее время поддерживает только',
    Message724: 'Формат файла',
    Message725: 'Можно загружать соответствующие файлы клиента или файлы обновления, разрешен только формат {0}, убедитесь, что вы загрузили правильный формат',
    Message726: 'Пожалуйста, загрузите файл версии',
    Message727: 'Можно загрузить только один файл',
    Message728: 'Произошла ошибка при удалении файлов',
    Message729: 'Ключевые слова для поиска',
    Message730: 'Тип',
    Message731: 'Обновить описание контента',
    Message732: 'Посмотреть содержимое обновления',
    Message733: 'Включить',
    Message734: 'Включение',
    Message735: 'Запрещать',
    Message736: 'Вы хотите удалить этот элемент?',
    Message737: 'Вы хотите удалить эти элементы?',
    Message738: 'Это действие отключит файлы версии',
    Message739: 'Отключено успешно',
    Message740: 'Включено',
    Message741: 'Эта операция изменит статус файла версии',
    Message742: 'Модификация отменена',
    Message743: 'номер телефона',
    Message744: 'Пароль ограничен {0} буквами, цифрами или символами, а пароль по умолчанию:',
    Message745: 'Имя поиска',
    Message746: 'Пакет одобрен',
    Message747: 'Пакет отклонен',
    Message748: 'Тип пользователя',
    Message749: 'Применить время',
    Message750: 'Отклоненный',
    Message751: 'Одобренный',
    Message752: 'соглашаться',
    Message753: 'Отклонить',
    Message754: 'Пожалуйста, выберите пункт, который необходимо согласовать',
    Message755: 'Пожалуйста, выберите элемент, который необходимо отклонить',
    Message756: 'Вы хотите отказаться?',
    Message757: 'Вы хотите согласиться?',
    Message758: 'Удалить?',
    Message759: 'Рассмотрено',
    Message760: 'Не проверено',
    Message761: 'Нет пунктов для согласия',
    Message762: 'Заявка одобрена',
    Message763: 'Нет элементов для отклонения',
    Message764: 'Заявка отклонена',
    Message765: 'Следует ли перезаписывать следующие телефонные номера',
    Message766: 'Заглушка',
    Message767: 'Шаблон импорта пользователей',
    Message768: 'Нет. {msg} неверное имя пользователя или номер телефона, проверьте',
    Message769: 'Формат данных может быть неправильным, пожалуйста, проверьте формат данных',
    Message770: 'Повторяющийся номер телефона',
    Message771: 'Не удалось импортировать пользователя',
    Message772: 'Перезаписано успешно',
    Message773: 'Поделитесь ссылкой-приглашением в группу и одновременно присоединитесь к группе',
    Message774: 'Пригласительная ссылка',
    Message775: 'Копировать ссылку',
    Message776: 'Нужно ли проверять участников при присоединении:',
    Message777: 'обзор',
    Message778: 'Не пересматривать',
    Message779: 'Создать новую ссылку',
    Message780: 'Скопировано успешно',
    Message781: 'Обзор участника',
    Message782: 'Добавить персонал',
    Message783: 'Импорт персонала',
    Message784: 'Приглашать',
    Message785: 'Экспорт персонала',
    Message786: 'Обработка данных',
    Message787: 'Статус пользователь',
    Message788: 'удалять',
    Message789: 'Вы хотите удалить пользователя?',
    Message790: 'Учетные записи этих пользователей будут аннулированы, а данные собраний, зарезервированные для этих учетных записей, будут очищены;',
    Message791: 'Сгенерированные исторические данные принадлежат предприятию и не будут очищены',
    Message792: 'Деактивировать этого пользователя? После деактивации учетная запись будет недоступна',
    Message793: 'Вы хотите активировать этого пользователя?',
    Message794: 'Хотите сбросить пароль?',
    Message795: 'Пароль будет сброшен на пароль по умолчанию',
    Message796: 'Изменить пользователя',
    Message797: 'Импортировать пользователя',
    Message798: 'Пригласить в группу',
    Message799: 'Пароль был сброшен на пароль по умолчанию, пожалуйста, сообщите об этом члену вовремя',
    Message800: 'Администратора нельзя удалить, он автоматически фильтруется для вас',
    Message801: 'Облачный диск конференции',
    Message802: 'завершенный',
    Message803: 'Загрузка',
    Message804: 'Ошибка',
    Message805: 'По умолчанию пароль председателя во время видеоконференции равен "1".',
    Message806: 'Отменить загрузку файла',
    Message807: 'Версия программы',
    Message808: 'обычная версия',
    Message809: 'Отечественная версия',
    Message810: 'имя файла не может превышать {num}.',
    Message811: 'общее количество файлов не может превышать {msg}',
    Message812: 'За один раз можно загрузить не более {num} файлов',
    Message813: 'комната для переговоров',
    Message814: 'Поиск по названию встречи/Номер встречи',
    Message815: 'Имя поиска/комната для переговоров/Модель устройства',
    Message816: 'Быстрая встреча',
    Message817: 'Тайная встреча',
    Message818: 'единица',
    Message819: 'Голосовать',
    Message820: 'Оценка удовлетворенности',
    Message821: 'Пожалуйста, введите название для голосования',
    Message822: 'Не удалось получить путь к файлу',
    Message823: 'Источник документа',
    Message824: 'Поиск по названию встречи/Букер/№ встречи',
    Message825: 'Тайм-аут запроса, максимальная продолжительность — 1 час.',
    Message826: 'Пожалуйста, введите название рейтинга',
    Message827: 'Если рейтинг менее 3 человек, для расчета балла автоматически будет использоваться среднее значение.',
    Message828: 'Одобрен',
    Message829: 'Не согласен',
    Message830: 'Воздержался',
    Message831: 'Доволен',
    Message832: 'В основном доволен',
    Message833: 'Неудовлетворенный',
    Message834: 'Поделитесь ссылкой-приглашением в группу и одновременно присоединитесь к группе',
    Message835: 'Требуется ли проверка администратором при присоединении участника:',
    Message836: 'Серийный номер терминала',
    Message837: 'Количество устройств (шт.)',
    Message838: 'Учетная запись пользователя',
    Message839: 'Конференц-зал(ы)',
    Message840: 'Этот формат кодирования видео или скорость передачи данных не поддерживают воспроизведение онлайн, скачайте и просмотрите.',
    Message841: 'Если шифрование файлов включено, данные во время встречи не могут быть сохранены локально на клиенте и не могут быть переданы другим.После встречи данные нельзя просмотреть на платформе, их можно только расшифровать и загрузить.',
    Message842: 'Добавить конференц-зал',
    Message843: 'пожалуйста',
    Message844: 'Управлять устройством',
    Message845: 'индивидуальный',
    Message846: 'люди',
    Message847: 'м',
    Message848: 'Второсортный',
    Message849: 'Имя поиска/номер телефона',
    Message850: 'Инструкции: Когда включено шифрование файлов, данные во время встречи не могут быть сохранены локально на клиенте и не могут быть переданы.Данные после встречи нельзя просмотреть на платформе, их можно только расшифровать и загрузить.',
    Message851: 'Зашифровать (файлы собраний можно открыть только в терминале)',
    Message852: 'Примечание：После того, как загрузка данных будет включена, обычным пользователям будет разрешено загружать данные собрания после собрания.',
    Message853: 'единицаID：',
    Message854: 'Длина адреса не может превышать50Б',
    Message855: 'Не удалось отредактировать,Попробуйте загрузить снова',
    Message856: 'В настоящее время поддерживает толькоzipФормат файла',
    Message857: 'Расформирование отряда является необратимой операцией.При расформировании отряда все его участники становятся отдельными учетными записями пользователей.',
    Message858: 'Это действие перезапишет исходный файл повестки дня., Вы хотите продолжать?',
    Message859: 'имя/Имя модуля/Тип операции',
    Message860: 'Это действие отключит файлы версии, Вы хотите продолжать?',
    Message861: 'Эта операция изменит статус файла версии, Вы хотите продолжать?',
    Message862: 'человек проголосовали',
    Message863: 'Поиск номера телефона/имя',
    Message864: 'Пароль ограничен 8-16 буквами, цифрами или символами, а пароль по умолчанию:为12345678',
    Message865: 'Поиск имен участников/номер телефона',
    Message866: 'Можно загружать соответствующие файлы клиента или файлы обновления, разрешен только формат zip, убедитесь, что вы загрузили правильный формат',
    Message867: 'Нажмите OK, чтобы завершить резервирование, или щелкните другой узел времени, чтобы завершить резервирование.',
    Message868: ',Или нажмите на другой временной узел, чтобы завершить бронирование.',
    Message869: 'Этот формат кодирования видео или скорость передачи данных не поддерживают воспроизведение онлайн, скачайте и просмотрите.',
    Message870: 'Нажмите, чтобы установить дату',
    Message871: 'Нажмите, чтобы настроить конференц-зал',
    Message872: 'Расширенный поиск',
    Message873: 'Содержание опции',
    Message874: 'Данные пользователя',
    Message875: 'Список опций',
    Message876: "копировать",
    Message877: "Скопировано успешно",
    Message878: "пожалуйста, введите свой адрес электронной почты",
    Message879: "Пожалуйста, введите телефона/адрес электронной почты",
    Message880: "Этот адрес электронной почты уже зарегистрирован, вы можете войти напрямую",
    Message881: "телефона/адрес электронной почты не может быть пустым.",
    Message882: "Зарегистрировать телефон",
    Message883: "Зарегистрировать ящик",
    Message884: "Код подтверждения отправлен на электронную почту",
  },
  add: {
    mes1: 'Текущая встреча зарезервирована',
    mes2: 'Бронирование',
    meetingDetails: 'Детали встречи'
  }
}